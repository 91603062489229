import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { Layout, SEO } from "../components"

import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
} from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

// const useStyles = makeStyles({})

class Project extends Component {
  render() {
    // const classes = useStyles()

    const { id, name, description, uri, gitHubUri, images, category } =
      this.props.data.contentfulProjects
    return (
      <Layout title={"Project Details"}>
        <SEO title={name} />
        {/* <img src={heroImage.file.url} alt="blog" /> */}

        <Grid key={id} item>
          <Link
            to="/portfolio"
            style={{
              marginLeft: "20px",
              color: "#FFC107",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row",
              textDecoration: "none",
            }}
          >
            <ArrowBackIosIcon />
            <span>Go back</span>
          </Link>
          <Card
            style={{
              margin: 6,
              minHeight: "230px",
              background:
                "linear-gradient( 159deg,rgba(37,37,50,0.98) 0%,rgba(35,35,45,0.98) 100% )",
              color: "white",
              boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.9)",
              padding: "5px",
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h4"
                style={{ fontWeight: "bolder" }}
                component="h2"
              >
                {name}
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  color: "#FFC107",
                }}
                gutterBottom
              >
                {category}
              </Typography>

              {images && (
                <CardMedia
                  style={{
                    padding: "20px 20px 0px 20px",
                    marginTop: 30,
                    marginBottom: 30,
                    boxShadow: "0 3px 8px 0 rgba(255, 255, 255, 0.2)",
                  }}
                  title={images[0].title}
                >
                  <img height="100%" src={`https://${images[0].file.url}`} />
                </CardMedia>
              )}
              <Typography variant="body2" component="p">
                <p
                  style={{ paddingTop: 10, paddingBottom: 7 }}
                  dangerouslySetInnerHTML={{
                    __html: description.description,
                  }}
                ></p>
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="large"
                style={{ backgroundColor: "rgb(255, 193, 7)" }}
              >
                <a
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                  target="_blank"
                  href={gitHubUri}
                >
                  Github
                </a>
              </Button>
              {uri && (
                <Button
                  size="large"
                  style={{
                    backgroundColor: "rgb(255, 193, 7)",
                  }}
                >
                  <a
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    href={uri}
                  >
                    Show Demo
                  </a>
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      </Layout>
    )
  }
}

Project.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Project

export const pageQuery = graphql`
  query projectQuery($slug: String!) {
    contentfulProjects(id: { eq: $slug }) {
      id
      description {
        description
      }
      name
      uri
      gitHubUri
      category
      images {
        id
        title
        file {
          url
        }
      }
    }
  }
`
